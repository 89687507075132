<template>
  <div class="bigDiv">
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049156165753929.jpg" alt="" class="btoFlex">
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049154687517127.gif" alt="" class="bto" @click="throttleSubmit">
  </div>
</template>

<script>
import { getWxCsad } from "../../api/360AD.js";

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      ruleForm: {

        land_link: "", //落地页链接
      },
    }
  },
  created () {
    this.ruleForm.land_link = window.location.href
  },
  methods: {
    throttleSubmit () {
      const currentTime = Date.now();
      if (currentTime - this.lastClickTime < 2500) {
        return; // 在一秒内多次点击，直接返回
      }
      this.lastClickTime = currentTime; // 更新上次点击时间

      this.wxClick()
    },
    async wxClick () {
      const { data } = await getWxCsad(this.ruleForm);
      window.location.href = data.data;
    },
  },
  mounted () {

  },
  watch: {

  },
  computed: {

  },
  filters: {

  }
}
</script>

<style scoped lang='less'>
.bigDiv {
  width: 100%;

  img {
    width: 100%;
  }
  .btoFlex {
    margin-bottom: 5px;
  }
  .bto {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
@media screen and (min-width: 750px) {
  .bigDiv {
    width: 750px;
    margin: 0 auto;
    img {
      width: 100%;
    }
    .btoFlex {
      margin-bottom: 5px;
    }
    .bto {
      width: 750px;
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
    }
  }
}
</style>